<template>
  <div
    class="form-builder__select"
    :class="schema.styleClasses || ''"
  >
    <q-select
      ref="select"
      :dark="$q.dark.isActive"
      :standout="schema.standout || (schema.color ? undefined : 'bg-teal text-white full-width')"
      :filled="!schema.noFilled"
      :color="schema.color || undefined"
      :model-value="schema.customLabel && schema.customLabel(schema.value) || schema.value"
      :options="schema.options"
      :label="`${schema.label} ${schema.required ? '*' : ''}`"
      :placeholder="schema.placeholder || ''"
      :disable="!!schema.disabled"
      :hide-bottom-space="!schema.hint"
      :hint="schema.hint || undefined"
      :error-message="schema.error"
      :error="!!schema.error"
      :dense="!!schema.dense"
    >
      <template v-slot:option="props">
        <div
          class="q-pa-sm card--clickable card--clickable-no-scale"
          :class="props.selected ? 'bg-success' : ''"
          @click="handleSelect(props.opt)"
        >
          <span v-if="schema.customLabel">
            {{ schema.customLabel(props.opt) }}
          </span>

          <span v-else>
            {{ props.opt }}
          </span>
        </div>
      </template>

      <template
        v-if="!schema.disabledClean"
        v-slot:append
      >
        <q-icon
          v-if="schema.value"
          name="close"
          class="cursor-pointer"
          @click.stop="handleSelect(null)"
        />
      </template>
    </q-select>
  </div>
</template>

<script>
export default {
  props: {
    schema: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    handleSelect (option) {
      this.$refs.select.hidePopup()

      if (this.schema.onChange) {
        this.schema.onChange(option, this.schema.value)
      }
    }
  }
}
</script>
